/* eslint quote-props: ["error", "consistent"] */
/* eslint-disable global-require */
export default {
  // INDEXING STARTS AT ZERO!!!
  // TYPES
  'situation': 'images/test_1_momentaufnahme.jpg',
  'problems': 'images/test_2_konflikt.jpg',
  'resources': 'images/test_3_ressourcen.jpg',
  'ressources': 'images/test_3_ressourcen.jpg',
  // CHOICES
  // SITUATION
  // Q1 - Womit können wir Dir jetzt gerade am besten helfen?
  'situation_question0_choice0': 'images/0_situation/q0/ich_moechte_gedanken_sortieren_und_rat_erhalten.jpg',
  'situation_question0_choice1': 'images/0_situation/q0/ich_moechte_infos_ueber_moeglichkeiten_erhalten.jpg',
  'situation_question0_choice2': 'images/0_situation/q0/ich_suche_nach_menschen_die_mir_zur_seite_stehen.jpg',
  // Q2 - Ich bin in der ... Schwangerschaftswoche.
  // NO IMAGES!!!
  // Q3 - Ich weiß seit ... von der Schwangerschaft.
  // NO IMAGES!!!
  // Q4 - Meine allererste Reaktion auf die Schwangerschaft:
  'situation_question3_choice0': 'images/0_situation/q3/angst_verzweiflung.jpg',
  'situation_question3_choice1': 'images/0_situation/q3/schock_ueberraschung.jpg',
  'situation_question3_choice2': 'images/0_situation/q3/freude.jpg',
  'situation_question3_choice3': 'images/0_situation/q3/durcheinander_der_gefuehle.jpg',
  'situation_question3_choice4': 'images/0_situation/q3/nichts_von_alledem.jpg',
  // Q5 - Meine bisherigen Schritte...
  // NO IMAGES!!!
  // Q6 - So oder ähnlich fühle ich mich momentan...
  // NO IMAGES!!!
  // Q7 - Wenn Du in der jetzigen Situation einen Wunsch frei hättest - was würdest Du Dir wünschen?
  'situation_question6_choice0': 'images/0_situation/q6/alles_ungeschehen_machen_zu_koennen.jpg',
  'situation_question6_choice1': 'images/0_situation/q6/dass_mir_jemand_die_entscheidung_abnimmt.jpg',
  'situation_question6_choice2': 'images/0_situation/q6/dass_ich_mich_einfach_freuen_koennte.jpg',
  // PROBLEMS
  // Q1 - Ich denke vor allem an eine Abtreibung, weil...
  'problems_question0_choice0': 'images/1_problems/er_das_kind_nicht_will.jpg',
  'problems_question0_choice1': 'images/1_problems/es_kompliziert_ist_zwischen_uns.jpg',
  'problems_question0_choice2': 'images/1_problems/der_zeitpunkt_nicht_passt.jpg',
  'problems_question0_choice3': 'images/1_problems/ich_mich_jetzt_schon_ueberlastet_fuehle.jpg',
  'problems_question0_choice4': 'images/1_problems/ich_nicht_im_richtigen_alter_fuer_ein_kind_bin.jpg',
  'problems_question0_choice5': 'images/1_problems/eigentlich_passt_alles_aber_ich_bin_trotzdem_unsicher.jpg',
  'problems_question0_choice6': 'images/1_problems/ich_materielle_sorgen_habe.jpg',
  'problems_question0_choice7': 'images/1_problems/aus_einem_anderen_grund.jpg',
  // Q2 - LEVEL2
  // Choice 1 - er das Kind nicht will
  'problems_question1_choice0_0': 'images/1_problems/path0/ich_habe_angst_alleinerziehend_zu_sein.jpg',
  'problems_question1_choice0_1': 'images/1_problems/path0/ich_habe_angst_ihn_zu_verlieren_wenn_ich_mich_fuer_das_kind_entscheide.jpg',
  'problems_question1_choice0_2': 'images/1_problems/path0/ich_habe_angst_wie_sich_die_beziehung_veraendert.jpg',
  'problems_question1_choice0_3': 'images/1_problems/path0/das_macht_mir_aus_einem_anderen_grund_sorge_und_belastet_mich.jpg',
  // Choice 2 - es schwierig ist zwischen uns
  'problems_question1_choice1_0': 'images/1_problems/path1/es_eigentlich_schon_aus_ist.jpg',
  'problems_question1_choice1_1': 'images/1_problems/path1/die_beziehung_nicht_gut_laeuft.jpg',
  'problems_question1_choice1_2': 'images/1_problems/path1/das_kind_von_einem_anderen_ist.jpg',
  'problems_question1_choice1_3': 'images/1_problems/path1/wir_kein_festes_paar_sind.jpg',
  'problems_question1_choice1_4': 'images/1_problems/path1/wir_uns_erst_seit_kurzem_kennen_noch_nicht_lange_ein_paar_sind.jpg',
  // Choice 3 - der Zeitpunkt nicht passt
  'problems_question1_choice2_0': 'images/1_problems/path2/wir_kennen_uns_erst_seit_kurzem_sind_noch_nicht_lange_ein_paar.jpg',
  'problems_question1_choice2_1': 'images/1_problems/path2/ich_bin_noch_in_schule_ausbildung_studium.jpg',
  'problems_question1_choice2_2': 'images/1_problems/path2/ich_habe_gerade_ganz_andere_plaene.jpg',
  'problems_question1_choice2_3': 'images/1_problems/path2/ich_wollte_erst_spaeter_kinder_haben.jpg',
  'problems_question1_choice2_4': 'images/1_problems/path2/die_familienplanung_ist_abgeschlossen.jpg',
  // Choice 4 - ich mich jetzt schon überlastet fühle
  'problems_question1_choice3_0': 'images/1_problems/path3/ich_bereits_mutter_bin_ich_weiss_nicht_wie_es_mit_noch_einem_kind_gehen_soll.jpg',
  'problems_question1_choice3_1': 'images/1_problems/path3/ich_bereits_alleinerziehend_bin.jpg',
  'problems_question1_choice3_2': 'images/1_problems/path3/die_berufliche_situation_mich_sehr_fordert.jpg',
  'problems_question1_choice3_3': 'images/1_problems/path3/wegen_gesundheitlicher_probleme.jpg',
  'problems_question1_choice3_4': 'images/1_problems/path3/die_familiaere_situation_mich_sehr_fordert.jpg',
  'problems_question1_choice3_5': 'images/1_problems/path3/aus_einem_anderen_grund_alles_zu_viel.jpg',
  // Choice 5 - ich nicht im richtigen Alter für ein Kind bin
  'problems_question1_choice4_0': 'images/1_problems/path4/ich_fuehle_mich_zu_jung.jpg',
  'problems_question1_choice4_1': 'images/1_problems/path4/ich_fuehle_mich_zu_alt.jpg',
  // Choice 6 - eigentlich passt alles, aber ich bin trotzdem unsicher
  'problems_question1_choice5_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question1_choice5_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question1_choice5_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question1_choice5_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question1_choice5_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 7 - ich materielle Sorgen habe
  'problems_question1_choice6_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question1_choice6_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question1_choice6_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question1_choice6_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question1_choice6_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 8 - nichts davon trifft zu - weiter
  'problems_question1_choice7_0': 'images/1_problems/path7/ich_medizinische_probleme_befuerchte.jpg',
  'problems_question1_choice7_1': 'images/1_problems/path7/meine_eltern_familie_mich_unter_druck_setzen.jpg',
  'problems_question1_choice7_2': 'images/1_problems/path7/aus_glaubens_kulturellen_gruenden.jpg',
  'problems_question1_choice7_3': 'images/1_problems/path7/ich_mich_meinem_arbeitgeber_verpflichtet_fuehle.jpg',
  'problems_question1_choice7_4': 'images/1_problems/path7/ich_vergewaltigt_wurde.jpg',
  'problems_question1_choice7_5': 'images/1_problems/path7/ich_weit_weg_von_meiner_heimat_lebe_mein_aufenthaltsstatus_unsicher_ist.jpg',
  'problems_question1_choice7_6': 'images/1_problems/path7/nichts_davon_trifft_zu_ich_moechte_gerne_meine_situation_in_eigenen_worten_schildern.jpg',
  // Q3 - LEVEL3
  // CHOICE 1 - ER DAS KIND NICHT WILL
  // Choice 1 - Choice 1 - ich habe Angst alleinerziehend zu sein
  'problems_question2_choice0_0_0': 'images/1_problems/path0/angst_es_alleine_mit_dem_kind_nicht_zu_schaffen.jpg',
  'problems_question2_choice0_0_1': 'images/1_problems/path0/dem_kind_keinen_vater_bieten_zu_koennen.jpg',
  'problems_question2_choice0_0_2': 'images/1_problems/path0/ich_bin_bereits_alleinerziehend.jpg',
  'problems_question2_choice0_0_3': 'images/1_problems/path0/ich_habe_angst_es_finanziell_nicht_zu_schaffen.jpg',
  // Choice 1 - Choice 2 - ich habe Angst, ihn zu verlieren, wenn ich mich für das Kind entscheide
  'problems_question2_choice0_1_0': 'images/1_problems/path0/keinen_partner_mehr_zu_finden.jpg',
  'problems_question2_choice0_1_1': 'images/1_problems/path0/dass_ich_dadurch_auch_freunde_und_die_gewohnte_umgebung_verlieren_wuerde.jpg',
  'problems_question2_choice0_1_2': 'images/1_problems/path0/sonstiges.jpg',
  // Choice 1 - Choice 3 - ich habe Angst, dass und wie sich Bezeiehung verändert
  'problems_question2_choice0_2_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice0_2_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice0_2_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice0_2_3': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 1 - Choice 4 - das macht mir aus einem anderen Grund Sorgen und belastet mich
  'problems_question2_choice0_3_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice0_3_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice0_3_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice0_3_3': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 2 - ES SCHWIERIG IST ZWISCHEN UNS
  // Choice 2 - Choice 1 - es eigentlich schon aus ist
  'problems_question2_choice1_0_0': 'images/1_problems/path1/ich_habe_angst_an_ihn_gebunden_zu_sein.jpg',
  'problems_question2_choice1_0_1': 'images/1_problems/path1/ich_habe_angst_alleinerziehend_zu_sein.jpg',
  // Choice 2 - Choice 2 - die Beziehung nicht gut läuft
  'problems_question2_choice1_1_0': 'images/1_problems/path1/ich_habe_angst_dass_es_durch_die_herausforderungen_eines_gemeinsamen_kindes_auseinander_geht.jpg',
  'problems_question2_choice1_1_1': 'images/1_problems/path1/ich_habe_angst_davor_in_einer_so_unsicheren_situation_ein_kind_zu_bekommen.jpg',
  // Choice 2 - Choice 3 - das Kind von einem anderen ist
  'problems_question2_choice1_2_0': 'images/1_problems/path1/ich_habe_angst_dass_durch_die_schwangerschaft_alles_auffliegt.jpg',
  'problems_question2_choice1_2_1': 'images/1_problems/path1/ich_habe_angst_dass_ich_am_ende_alleine_mit_dem_kind_dastehe.jpg',
  // Choice 2 - Choice 4 - wir kein festes Paar sind
  'problems_question2_choice1_3_0': 'images/1_problems/path1/ich_habe_angst_an_ihn_gebunden_zu_sein_festes_paar.jpg',
  'problems_question2_choice1_3_1': 'images/1_problems/path1/ich_habe_angst_alleinerziehend_zu_sein_festes_paar.jpg',
  // Choice 2 - Choice 5 - wir uns erst seit kurzem kennen / noch nicht lange ein Paar sind
  'problems_question2_choice1_4_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice1_4_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice1_4_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice1_4_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice1_4_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 3 - DER ZEITPUNKT NICHT PASST
  // Choice 3 - Choice 1 - wir kennen uns erst seit kurzem
  'problems_question2_choice2_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice2_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice2_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice2_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice2_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 3 - Choice 2 - ich bin noch in Schule / Studium / Ausbildung
  'problems_question2_choice2_1_0': 'images/1_problems/path2/ich_habe_angst_das_finanziell_nicht_stemmen_zu_koennen.jpg',
  'problems_question2_choice2_1_1': 'images/1_problems/path2/ich_habe_angst_dadurch_ohne_ausbildung_abschluss_dazustehen.jpg',
  // Choice 3 - Choice 3 - ich habe gerade ganz andere Pläne
  'problems_question2_choice2_2_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice2_2_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice2_2_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice2_2_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice2_2_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 3 - Choice 4 - ich wollte erst später Kinder haben
  'problems_question2_choice2_3_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice2_3_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice2_3_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice2_3_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice2_3_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 3 - Choice 5 - die Familienplanung ist abgeschlossen
  'problems_question2_choice2_4_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice2_4_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice2_4_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice2_4_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice2_4_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 4 - ICH MICH JETZT SCHON ÜBERLASTET FÜHLE
  // Choice 4 - Choice 1 - ich bereits Mutter bin. Ich weiß nicht,
  // wie es mit dem Kind weiter gehen soll
  'problems_question2_choice3_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice3_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice3_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice3_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice3_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 4 - Choice 2 - ich bereits alleinerziehend bin
  'problems_question2_choice3_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice3_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice3_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice3_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice3_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 4 - Choice 3 - die berufliche Situation mich sehr fordert
  'problems_question2_choice3_2_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice3_2_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice3_2_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice3_2_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice3_2_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 4 - Choice 4 - ich gesundheitliche Probleme habe
  'problems_question2_choice3_3_0': 'images/1_problems/path3/psychisch.jpg',
  'problems_question2_choice3_3_1': 'images/1_problems/path3/koerperlich.jpg',
  // Choice 4 - Choice 5 - die familiäre Situation mich sehr fordert
  'problems_question2_choice3_4_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice3_4_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice3_4_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice3_4_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice3_4_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 4 - Choice 6 - mir momentan aus einem anderen Grund alles zu viel ist
  'problems_question2_choice3_5_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice3_5_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice3_5_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice3_5_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice3_5_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 5 - ICH NICHT IM RICHTIGEN ALTER FÜR EIN KIND BIN
  // Choice 5 - Choice 1 - zu jung
  'problems_question2_choice4_0_0': 'images/1_problems/path4/ich_habe_angst_dass_es_mich_ueberfordern_koennte.jpg',
  'problems_question2_choice4_0_1': 'images/1_problems/path4/ich_habe_sorge_viel_zu_verpassen.jpg',
  'problems_question2_choice4_0_2': 'images/1_problems/path4/ich_fuehle_mich_selbst_noch_wie_ein_kind.jpg',
  'problems_question2_choice4_0_3': 'images/1_problems/path4/es_wuerde_den_klassischen_lebensplan_durcheinander_bringen.jpg',
  'problems_question2_choice4_0_4': 'images/1_problems/path4/ich_bin_noch_in_schule_ausbildung_studium.jpg',
  // Choice 5 - Choice 2 - zu alt
  'problems_question2_choice4_1_0': 'images/1_problems/path4/die_familienplanung_war_schon_abgeschlossen.jpg',
  'problems_question2_choice4_1_1': 'images/1_problems/path4/ich_wollte_mich_jetzt_auf_meinen_beruf_konzentrieren.jpg',
  'problems_question2_choice4_1_2': 'images/1_problems/path4/ich_habe_angst_dass_das_kind_krank_sein_koennte_wegen_meines_alters.jpg',
  'problems_question2_choice4_1_3': 'images/1_problems/path4/ich_habe_angst_vor_der_veraenderung.jpg',
  'problems_question2_choice4_1_4': 'images/1_problems/path4/ich_habe_angst_dass_ich_eine_zu_alte_mutter_bin.jpg',
  // CHOICE 8 - NICHTS DAVON TRIFFT ZU - WEITER
  // Choice 8 - Choice 1 - ich medizinische Probleme befürchte
  'problems_question2_choice7_0_0': 'images/1_problems/path7/ich_habe_eine_koerperliche_erkrankung.jpg',
  'problems_question2_choice7_0_1': 'images/1_problems/path7/ich_leide_unter_einer_psychischen_erkrankung.jpg',
  'problems_question2_choice7_0_2': 'images/1_problems/path7/ich_erwarte_mehrlinge.jpg',
  'problems_question2_choice7_0_3': 'images/1_problems/path7/ich_hatte_probleme_bei_frueheren_schwangerschaften.jpg',
  'problems_question2_choice7_0_4': 'images/1_problems/path7/kind_ist_krank_behindert_oder_koennte_es_sein.jpg',
  'problems_question2_choice7_0_5': 'images/1_problems/path7/der_vater_des_kindes_ist_krank.jpg',
  // Choice 8 - Choice 2 - meine Eltern / Familie mich unter Druck setzen
  'problems_question2_choice7_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice7_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice7_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice7_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice7_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 8 - Choice 3 - aus Glaubens-/kulturellen Gründen
  'problems_question2_choice7_2_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice7_2_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice7_2_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice7_2_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice7_2_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 8 - Choice 4 - ich mich meinem Arbeitgeber verpflichtet fühle
  'problems_question2_choice7_3_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice7_3_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice7_3_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice7_3_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice7_3_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 8 - Choice 5 - ich vergewaltigt wurde - SPECIAL CASE - ohne Partner sagt JA!!!
  'problems_question2_choice7_4_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice7_4_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice7_4_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice7_4_3': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 8 - Choice 6 - ich weit weg von meiner Heimat lebe
  'problems_question2_choice7_5_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question2_choice7_5_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question2_choice7_5_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question2_choice7_5_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question2_choice7_5_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Q4 - LEVEL4
  // CHOICE 1 - CHOICE 1 - ICH HABE ANGST ALLEINERZIEHEND ZU SEIN
  // Choice 1.1.1 - ich habe Angst, es alleine nicht zu schaffen
  'problems_question3_choice0_0_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice0_0_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice0_0_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice0_0_0_3': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 1.1.2 - ich habe Angst, dem Kind keinen Vater bieten zu können
  'problems_question3_choice0_0_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice0_0_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice0_0_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice0_0_1_3': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 1.1.3 - Ich bin/war bereits alleinerziehend und möchte es nicht noch einmal sein
  'problems_question3_choice0_0_2_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice0_0_2_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice0_0_2_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice0_0_2_3': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 1.1.4 - Ich habe Angst, es alleine finanziell nicht zu schaffen
  'problems_question3_choice0_0_3_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice0_0_3_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice0_0_3_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice0_0_3_3': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 1 - CHOICE 2 - ICH HABE ANGST IHN ZU VERLIEREN WENN ICH MICH FÜR DAS KIND ENTSCHEIDE
  // Choice 1.2.1 - keinen Partner mehr zu finden
  'problems_question3_choice0_1_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice0_1_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice0_1_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice0_1_0_3': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 1.2.2 - dass ich dadurch auch Freude und die gewohnte Umgebung verlieren würde
  'problems_question3_choice0_1_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice0_1_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice0_1_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice0_1_1_3': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 1.2.3 - Sonstiges
  'problems_question3_choice0_1_2_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice0_1_2_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice0_1_2_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice0_1_2_3': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 2 - CHOICE 1 - ES EIGENTLICH SCHON AUS IST
  // Choice 2.1.1 - ich habe Angst, an ihn gebunden zu sein
  'problems_question3_choice1_0_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice1_0_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice1_0_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice1_0_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice1_0_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 2.1.2 - ich habe Angst, alleinerziehend zu sein
  'problems_question3_choice1_0_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice1_0_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice1_0_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice1_0_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice1_0_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 2 - CHOICE 2 - DIE BEZIEHUNG NICHT GUT LÄUFT
  // Choice 2.2.1 - ich habe Angst, dass es durch die Herausforderungen
  // eines gemeinsamen Kindes auseinander geht
  'problems_question3_choice1_1_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice1_1_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice1_1_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice1_1_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice1_1_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 2.2.2 - ich habe Angst davor, in einer so unsicheren Situation ein Kind zu bekommen
  'problems_question3_choice1_1_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice1_1_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice1_1_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice1_1_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice1_1_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 2 - CHOICE 3 - DAS KIND VON EINEM ANDEREN IST
  // Choice 2.3.1 - ich habe Angst, dass durch die Schwangerschaft alles auffliegt
  'problems_question3_choice1_2_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice1_2_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice1_2_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice1_2_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice1_2_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 2.3.2 - ich habe Angst, dass ich am Ende alleine mit dem Kind dastehe
  'problems_question3_choice1_2_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice1_2_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice1_2_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice1_2_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice1_2_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 2 - CHOICE 4 - WIR KEIN FESTES PAAR SIND
  // Choice 2.4.1 - ich habe Angst, dadurch an ihn gebunden zu sein
  'problems_question3_choice1_3_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice1_3_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice1_3_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice1_3_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice1_3_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 2.4.2 - ich habe Angst, alleinerziehend zu sein
  'problems_question3_choice1_3_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice1_3_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice1_3_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice1_3_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice1_3_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 3 - CHOICE 2 - ICH BIN NOCH IN SCHULE / STUDIUM / AUSBILDUNG
  // Choice 3.2.1 - Angst, es finanziell nicht zu schaffen
  'problems_question3_choice2_1_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice2_1_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice2_1_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice2_1_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice2_1_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 3.2.2 - Angst, ohne Ausbildung dazustehen
  'problems_question3_choice2_1_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice2_1_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice2_1_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice2_1_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice2_1_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 4 - CHOICE 4 - ICH GESUNDHEITLICHE PROBLEME HABE
  // Choice 4.4.1 - psychisch
  'problems_question3_choice3_3_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice3_3_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice3_3_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice3_3_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice3_3_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 4.4.2 - körperlich
  'problems_question3_choice3_3_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice3_3_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice3_3_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice3_3_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice3_3_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 5 - CHOICE 1 - ZU JUNG
  // Choice 5.1.1 - ich habe Angst, dass ich mich überfordern könnte
  'problems_question3_choice4_0_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice4_0_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice4_0_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice4_0_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice4_0_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 5.1.2 - ich habe Sorgen, so viel zu verpassen
  'problems_question3_choice4_0_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice4_0_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice4_0_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice4_0_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice4_0_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 5.1.3 - ich fühle mich selbst noch wie ein Kind
  'problems_question3_choice4_0_2_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice4_0_2_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice4_0_2_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice4_0_2_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice4_0_2_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 5.1.4 - würde den 'klassischen' Lebensplan durcheinander bringen
  'problems_question3_choice4_0_3_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice4_0_3_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice4_0_3_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice4_0_3_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice4_0_3_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 5.1.5 - ich bin noch in Schule / Studium / Ausbildung
  'problems_question3_choice4_0_4_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice4_0_4_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice4_0_4_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice4_0_4_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice4_0_4_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 5 - CHOICE 2 - ZU ALT
  // Choice 5.2.1 - die Familienplanung ist schon abgeschlossen
  'problems_question3_choice4_1_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice4_1_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice4_1_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice4_1_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice4_1_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 5.2.2 - ich wollte mich auf meinen Beruf konzentrieren
  'problems_question3_choice4_1_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice4_1_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice4_1_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice4_1_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice4_1_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 5.2.3 - ich habe Angst, dass das Kind krank sein könnte auf Grund meines Alters
  'problems_question3_choice4_1_2_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice4_1_2_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice4_1_2_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice4_1_2_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice4_1_2_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 5.2.4 - ich habe Angst vor der Veränderung
  'problems_question3_choice4_1_3_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice4_1_3_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice4_1_3_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice4_1_3_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice4_1_3_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 5.2.5 - ich habe Angst, dass wir zu alte Eltern sind
  'problems_question3_choice4_1_4_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice4_1_4_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice4_1_4_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice4_1_4_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice4_1_4_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // CHOICE 8 - CHOICE 1 - ICH BEFÜRCHTE MEDIZINISCHE PROBLEME
  // Choice 8.1.1 - ich habe eine körperliche Erkrankung
  'problems_question3_choice7_0_0_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice7_0_0_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice7_0_0_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice7_0_0_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice7_0_0_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 8.1.2 - ich leide unter einer psychischen Erkrankung
  'problems_question3_choice7_0_1_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice7_0_1_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice7_0_1_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice7_0_1_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice7_0_1_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 8.1.3 - ich erwarte Mehrlinge
  'problems_question3_choice7_0_2_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice7_0_2_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice7_0_2_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice7_0_2_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice7_0_2_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 8.1.4 - ich hatte Probleme bei früheren Schwangerschaften
  'problems_question3_choice7_0_3_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice7_0_3_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice7_0_3_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice7_0_3_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice7_0_3_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 8.1.5 - das Kind ist krank/behindert oder könnte es sein
  'problems_question3_choice7_0_4_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice7_0_4_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice7_0_4_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice7_0_4_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice7_0_4_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // Choice 8.1.6 - der Vater des Kindes ist krank
  'problems_question3_choice7_0_5_0': 'images/1_problems/last_question/jemand_der_mir_zuhoert_und_fuer_mich_da_ist.jpg',
  'problems_question3_choice7_0_5_1': 'images/1_problems/last_question/zeit_fuer_mich.jpg',
  'problems_question3_choice7_0_5_2': 'images/1_problems/last_question/ein_plan.jpg',
  'problems_question3_choice7_0_5_3': 'images/1_problems/last_question/dass_mein_partner_ja_zum_kind_sagt.jpg',
  'problems_question3_choice7_0_5_4': 'images/1_problems/last_question/konkrete_praktische_oder_finanzielle_unterstuetzung.jpg',
  // RESOURCES
  // Q1 - Diese Beschreibung kommt meiner Persönlichkeit am nächsten...
  'resources_question0_choice0': 'images/2_ressources/q0/die_unabhaengige.jpg',
  'resources_question0_choice1': 'images/2_ressources/q0/die_feinsinnige.jpg',
  'resources_question0_choice2': 'images/2_ressources/q0/die_leidenschaftliche.jpg',
  'resources_question0_choice3': 'images/2_ressources/q0/der_ruhepol.jpg',
  'resources_question0_choice4': 'images/2_ressources/q0/die_abenteurerin.jpg',
  // Q2 - In schwierigen Situationen höre ich am ehesten auf...
  'resources_question1_choice0': 'images/2_ressources/q1/meinen_verstand.jpg',
  'resources_question1_choice1': 'images/2_ressources/q1/mein_bauchgefuehl.jpg',
  'resources_question1_choice2': 'images/2_ressources/q1/auf_beides.jpg',
  // Q3 - Was meinem Leben Sinn verleiht...
  'resources_question2_choice0': 'images/2_ressources/q2/beziehungen_zu_anderen_menschen.jpg',
  'resources_question2_choice1': 'images/2_ressources/q2/meine_talente_einzubringen.jpg',
  'resources_question2_choice2': 'images/2_ressources/q2/schoene_erlebnisse.jpg',
  'resources_question2_choice3': 'images/2_ressources/q2/echtheit_meinen_ueberzeugungen_treu_bleiben.jpg',
  // Q4 - Wer ist für mich da?
  'resources_question3_choice0': 'images/2_ressources/q3/mein_partner.jpg',
  'resources_question3_choice1': 'images/2_ressources/q3/meine_eltern.jpg',
  'resources_question3_choice2': 'images/2_ressources/q3/meine_geschwister_andere_verwandte.jpg',
  'resources_question3_choice3': 'images/2_ressources/q3/meine_freunde.jpg',
  'resources_question3_choice4': 'images/2_ressources/q3/ich_weiss_im_moment_niemanden.jpg',
  // Q5 - Wie habe ich früher eine schwierige Situation gemeistert?
  'resources_question4_choice0': 'images/2_ressources/q4/indem_ich_versucht_habe_das_positive_in_der_situation_zu_sehen.jpg',
  'resources_question4_choice1': 'images/2_ressources/q4/ich_habe_nie_mein_ziel_aus_den_augen_verloren.jpg',
  'resources_question4_choice2': 'images/2_ressources/q4/durch_gespraeche_mit_anderen.jpg',
  'resources_question4_choice3': 'images/2_ressources/q4/indem_ich_in_ruhe_nachgedacht_habe.jpg',
  'resources_question4_choice4': 'images/2_ressources/q4/durch_mein_urvertrauen_in_das_leben.jpg',
  'resources_question4_choice5': 'images/2_ressources/q4/ich_weiss_nicht_aber_irgendwie_habe_ich_es_immer_geschafft.jpg',
};
